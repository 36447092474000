import React, {useEffect} from 'react';
import {Popup} from '@partssourceinc/react-ui-core';

const CustomerAdminChangeInformDialog = ({onConfirm, title, msg}) => {
    useEffect(() => {
        let msgEl = document.getElementsByClassName('msg')[0];

        if (msgEl) {
            msgEl.innerHTML = msg;
        }
    }, []);

    return (
        <Popup
            confirmText="Done"
            cancelText={null}
            show={true}
            hideButtons={false}
            onConfirm={onConfirm}
            onCancel={onConfirm}
            loading={false}
        >
            <h1 className="title">{title}</h1>
            <div className="msg" />
        </Popup>
    );

}

export default CustomerAdminChangeInformDialog;
